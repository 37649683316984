<template>
    <div class="wrapper_w">
        <van-popup v-model="show" position="bottom" round @click-overlay="popClick">
            <div class="wrapper">
                <div class="block">
                    <div v-if="!loading">
                        <div class="wrapper_title">{{detailObj.goods_name}}</div>
                        <ul class="wrapper_main">
                            <li v-if="Number(detailObj.goods_lv) > 0 || Number(detailObj.need_zslevel) > 0 || Number(detailObj.hp) > 0 || Number(detailObj.mp) > 0 || Number(detailObj.need_lv) > 0">
                                <div v-if="Number(detailObj.goods_lv)" class="star_w">
                                    <!-- <span>升星：</span> -->
                                    <van-rate v-model="detailObj.goods_lv" :count="detailObj.goods_lv" :size="14" color="#ffd21e" readonly />
                                </div>
                                <div v-if="Number(detailObj.need_zslevel)"><span>需转生等级：</span><span>{{detailObj.need_zslevel}}</span></div>
                                <div v-if="Number(detailObj.need_lv)"><span>需要等级：</span><span>{{detailObj.need_lv}}</span></div>
                                <div v-if="detailObj.hp.length"><span>hp：</span><span>{{detailObj.hp}}</span></div>
                                <div v-if="detailObj.mp.length"><span>mp：</span><span>{{detailObj.mp}}</span></div>
                            </li>
                            <li v-if="detailObj.basics_attribute.length">
                                <div class="attri_title"><span class="blue">【基础属性】：</span></div>
                                <div v-for="item in detailObj.basics_attribute" :key="item.id">
                                    <span>{{item.title}}：</span><span>{{item.title_value}} <span v-if="item.addition">（+{{item.addition}}）</span></span>
                                </div>
                            </li>
                            <li v-if="detailObj.element_attribute.length" class="purple">
                                <div>
                                    <div class="attri_title"><span class="blue">【元素属性】：</span></div>
                                    <div v-for="item in detailObj.element_attribute" :key="item.id">
                                        <span>{{item.title}}：</span><span>{{item.title_value}} <span v-if="item.addition">（+{{item.addition}}）</span></span>
                                    </div>
                                </div>
                            </li>
                            <li v-if="detailObj.additional_attribute.length">
                                <div>
                                    <div class="attri_title"><span class="blue">【额外属性】：</span></div>
                                    <div v-for="item in detailObj.additional_attribute" :key="item.id">
                                        <span>{{item.title}}：</span><span>{{item.title_value}}</span>
                                    </div>
                                </div>
                            </li>
                            <li v-if="detailObj.animals_attribute.length">
                                <div><span class="blue">【生肖属性】：</span></div>
                                <div v-for="item in detailObj.animals_attribute" :key="item.id">
                                    <span>{{item.title}}：</span><span>{{item.title_value}}</span>
                                </div>
                            </li>
                            <li v-if="detailObj.sacrifice_attribute&&detailObj.sacrifice_attribute.length">
                                <div><span class="blue">【血祭属性】：</span></div>
                                <div v-for="item in detailObj.sacrifice_attribute" :key="item.id">
                                    <span>{{item.title}}：</span><span>{{item.title_value}}</span>
                                </div>
                            </li>
                            <li v-if="detailObj.suit_attribute.length">
                                <div class="attri_title"><span class="blue">【套装属性】：({{detailObj.wear_suit}} / {{detailObj.total_suit}})</span></div>
                                <div v-for="item in detailObj.suit_attribute" :key="item.id">
                                    <span v-html="item.all_goods"></span>
                                    <div><span>{{item.title}}</span></div>
                                    <div><span v-html="item.desp"></span></div>
                                </div>
                            </li>
                            <li v-if="detailObj.sacrifice_suit_attribute&&detailObj.sacrifice_suit_attribute.length">
                                <div class="attri_title"><span class="blue">【血祭套装属性】：({{detailObj.wear_suit}} / {{detailObj.total_suit}})</span></div>
                                <div v-for="item in detailObj.sacrifice_suit_attribute" :key="item.id">
                                    <span v-html="item.all_goods"></span>
                                    <div><span>{{item.title}}</span></div>
                                    <div><span v-html="item.desp"></span></div>
                                </div>
                            </li>
                            <li v-if="detailObj.goods_desp.length">
                                <div>
                                    <div class="attri_title"><span class="blue">【描述】：</span></div>
                                    <div>
                                        <span v-html="detailObj.goods_desp"></span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div style="padding-top: 10rem;text-align: center;" v-else>
                        <van-loading color="#0094ff" size="24px">加载中...</van-loading>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>
export default {
    name: 'ItemDetail',
    props: {
        detailObj1: {
            type: Object,
            default: function () {
                return {}
            }
        },
        loading: {
            type: Boolean,
            default: true
        },
        wrapperShow: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showSet: false,
        }
    },
    computed: {
        show: {
            get: function () {
                return this.wrapperShow;
            },
            set: function (newValue) {
                //console.log(newValue);
                this.showSet = newValue;
            }
        },
        detailObj: function () {
            return this.detailObj1;
        },
    },
    created() {
        //console.log(this.show);
        //this.detailObj = this.detailObj1;
    },
    methods: {
        popClick() {
            this.$emit('popShow', this.showSet);
        }
    }
}
</script>
<template>
    <div class="property public_bg">
        <Top  :name="topName" email back v-if="topShow"></Top>
        <van-loading v-if="isLoading" color="#1989fa" />
        <div v-else class="public_tw">
            <div class="public_sheet" v-for="(item, index) in arrList" :key="index">
                <div class="public_main">
                    <div class="public_main_outside" v-for="(second, index) in item" :key="index">
                        <div class="public_main_outside_top">{{second['mean']}}</div>
                        <ul :class="second['cols'] == '2' ? 'towCols' : ''" v-if="second['data'] instanceof Array ? (second['data'].length > 0 ? true : false) : true">
                            <li v-for="(child, index) in second['data']" :key="index" :class="child.api ? 'liColor' : (typeof(child.val) != 'object' ? '' : 'longwenLi')" @click="handleClick(child)">
                                <span :class="typeof(child.val) != 'object' ? '' : 'displaySpan'">{{child.name}}{{child.val !== '' ? '：' : ''}}</span>
                                <span v-if="typeof(child.val) != 'object' ? true : false" v-html="child.val"></span>
                                <span v-else class="longSpan">
                                    <span v-for="item in child.val" :key="item.id">{{item.name}}: {{item.val}}</span>
                                </span>
                            </li>
                        </ul>
                        <span class="empty_span" v-else>无</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <van-popup v-model="show" position="bottom" round>
            <div class="wrapper">
                <div class="block">
                    <div v-if="!loading">
                        <div class="wrapper_title orange">{{detailObj.goods_name}}</div>
                        <ul class="wrapper_main">
                            <li v-if="Number(detailObj.goods_lv) > 0 || Number(detailObj.need_zslevel) > 0 || Number(detailObj.hp) > 0 || Number(detailObj.mp) > 0 || Number(detailObj.need_lv) > 0">
                                <div v-if="Number(detailObj.goods_lv) > 0 ? true : false" class="star_w"><span>升星：</span><van-rate v-model="detailObj.goods_lv" :count="detailObj.goods_lv" :size="14" color="#ffd21e" readonly /></div>
                                <div v-if="Number(detailObj.need_zslevel) > 0 ? true : false"><span>需转生等级：</span><span>{{detailObj.need_zslevel}}</span></div>
                                <div v-if="Number(detailObj.need_lv) > 0 ? true : false"><span>需要等级：</span><span>{{detailObj.need_lv}}</span></div>
                                <div v-if="detailObj.hp.length > 0 ? true : false"><span>hp：</span><span>{{detailObj.hp}}</span></div>
                                <div v-if="detailObj.mp.length > 0 ? true : false"><span>mp：</span><span>{{detailObj.mp}}</span></div>
                            </li>
                            <li v-if="detailObj.basics_attribute.length > 0 ? true : false">
                                <div><span class="newGreen">【基础属性】：</span></div>
                                <div v-for="item in detailObj.basics_attribute" :key="item.id">
                                    <span>{{item.title}}：</span><span>{{item.title_value}} <span v-if="item.addition ? true : false">（+{{item.addition}}）</span></span>
                                </div>
                            </li>
                            <li v-if="detailObj.element_attribute.length > 0 ? true : false">
                                <div>
                                    <div><span class="newGreen">【元素属性】：</span></div>
                                    <div v-for="item in detailObj.element_attribute" :key="item.id">
                                        <span>{{item.title}}：</span><span>{{item.title_value}} <span v-if="item.addition ? true : false">（+{{item.addition}}）</span></span>
                                    </div>
                                </div>
                            </li>
                            <li v-if="detailObj.additional_attribute.length > 0 ? true : false">
                                <div>
                                    <div><span class="newGreen">【额外属性】：</span></div>
                                    <div v-for="item in detailObj.additional_attribute" :key="item.id">
                                        <span>{{item.title}}：</span><span>{{item.title_value}}</span>
                                    </div>
                                </div>
                            </li>
                            <li v-if="detailObj.suit_attribute.length > 0 ? true : false">
                                <div><span class="newGreen">【套装属性】：({{detailObj.wear_suit}} / {{detailObj.total_suit}})</span></div>
                                <div v-for="item in detailObj.suit_attribute" :key="item.id">
                                    <span v-html="item.all_goods"></span>
                                    <div><span>{{item.title}}</span></div>
                                    <div><span v-html="item.desp"></span></div>
                                </div>
                            </li>
                            <li v-if="detailObj.goods_desp.length > 0 ? true : false">
                                <div>
                                    <div><span class="newGreen">【描述】：</span></div>
                                    <div>
                                        <span v-html="detailObj.goods_desp"></span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div style="padding-top: 10rem;text-align: center;" v-else>
                        <van-loading color="#0094ff" size="24px">加载中...</van-loading>
                    </div>
                </div>
            </div>
        </van-popup> -->
        <ItemDetail :detailObj1="detailObj" :loading="loading" :wrapperShow="wrapperShow" @popShow="popShowClick" ></ItemDetail>
    </div>
</template>
<script>
import Top from '@/components/top'
import ItemDetail from '@/components/itemDetail'
import base from '@/api/base'; //导入接口域名列表
import { get, post } from '@/utils/request'; //引入请求方法

export default {
    name:"property",
    data(){
        return {
            topName: '',
            isLoading:true,
            list:{},
            topShow: true,
            arrList: [],
            show: false,
            loading: true,
            params: {},
            wrapperShow: false,
            detailObj: {
                goods_name: '',
                hp: '',
                mp: "",
                need_lv: "",
                goods_lv: '',
                need_zslevel: "",
                goods_desp: "",
                additional_attribute: [],
                basics_attribute: [],
                element_attribute: [],
                suit_attribute: []
            },
        }
    },
    components: {
      Top,
      ItemDetail
    },
    created() {
        //获取路由参数
        this.params = this.$route.query;
        //console.log(this.params);
        this.topName = this.params.name;
        //有tag参数是app访问，需去掉头部
        if (this.params.tag) {
            this.topShow = false;
        }else{
            this.topShow = true;
        }
        //获取穿戴数据
        this.getEquipmentData(this.params);
    },
    methods: {
        //获取背包数据
        getEquipmentData(params) {
            var data = {
                goods_id : params.goods_id,
                is_product: 1
            };
            switch(params.key) {
                case 'property':
                    //状态属性
                    data.is_product = 1;
                    break;
                case 'role':
                    //角色提升
                    data.is_product = 1;
                    data.channel_id = params.channel_id;
                    break;
                case 'figure':
                    //龙纹
                    data.is_product = 1;
                    break;
                case 'spiritpet':
                    //灵兽
                    data.is_product = 1;
                    data.channel_id = params.channel_id;
                    break;
                case 'modelclothes':
                    //穿戴装备/时装
                    break;
                case 'knapsack':
                    //背包
                    break;
                case 'warehouse':
                    //仓库
                    break;
                case 'activityWareHouse':
                    //活动仓库
                    break;
                case 'treasureWareHouse':
                    //秘宝仓库
                    break;
            }

            this.dataRequest(params.api, data);
        },
        //获取数据请求
        dataRequest(api, param) {
            post(`${base.bd}${api}`, param).then(res => {
                this.isLoading = false;
                if (res.code == 200) {
                    let data = res.data.data, arr = [], childArr = [], newArr = [], pos_row, pos_col, eqObj = {}, newArr1 = [];
                    //添加行列字段，重组数组
                    data.forEach(item => {
                        pos_row = item.position.split('_')[0];
                        pos_col = item.position.split('_')[1];
                        //对子数组以id排序
                        //item.data = Object.values(item.data).sort(this.arrSort('id'));
                        if (item.data instanceof Array && api.includes('getAttach')) {
                            newArr1 = [];
                            item.data.forEach((item) => {
                                newArr1.push(Object.values(item)[0]);
                            });
                            item.data = newArr1.sort(this.arrSort('id'));
                            
                        } else {
                            item.data = Object.values(item.data).sort(this.arrSort('id'));
                        }
                        
                        arr.push({
                            row: pos_row,
                            col: pos_col,
                            con: item
                        });
                    });
                    //console.log(arr);
                    //根据行排序,并且将位于同一行的放在一个数组里，重组数组
                    arr.sort(this.arrSort('row')).forEach(item => {
                        let { row } = item;
                        if (!eqObj[row]) {
                            eqObj[row] = [];
                        }
                        eqObj[row].push(item);
                    });
                    //console.log( Object.values(eqObj));
                    //根据列排序
                    Object.values(eqObj).forEach(item => {
                        if (item.length > 1) {
                            item.sort(this.arrSort('col'));
                        }
                        childArr.push(item);
                    });
                    //console.log(childArr);
                    //去掉行列参数，将最终内容放进数组
                    for (var i = 0; i < childArr.length; i++) {
                        var arr4 = [];
                        for (var j = 0; j < childArr[i].length; j++) {
                            arr4.push(childArr[i][j]['con']);
                        }
                        newArr.push(arr4);
                    }
                    this.arrList = newArr;
                }
            }).catch(err => {
                //this.isLoading = false;
                //console.log(err);
            });
        },
        //根据数组属性排序
        arrSort(keyName) {
           return function (obj1, obj2) {
               var a = obj1[keyName], b = obj2[keyName];
               if (a < b) return -1
               else if (a > b) return 1
               else return 0
           }
        },
        //点击看装备详情
        handleClick(item) {
            //console.log(item);
            if (item.api) {
                this.wrapperShow = true;
                this.loading = true;
                //初始化
                this.detailObj = {
                    goods_name: '',
                    hp: '',
                    mp: "",
                    need_lv: "",
                    goods_lv: '',
                    need_zslevel: "",
                    goods_desp: "",
                    additional_attribute: [],
                    basics_attribute: [],
                    element_attribute: [],
                    suit_attribute: []
                };
                post(`${base.bd}${item.api}`, {
                    goods_id: this.params.goods_id,
                    item_id: item.item_id
                }).then(res => {
                    // console.log(res)
                    if (res.code == 200) {
                        this.loading = false;
                        for (var key in res.data) {
                            this.detailObj = res.data[key];
                            // console.log(res.data[key])
                            //处理额外属性
                            this.getArray(this.detailObj, 'additional_attribute');
                            //处理基础属性
                            this.getArray(this.detailObj, 'basics_attribute');
                            //处理元素属性
                            this.getArray(this.detailObj, 'element_attribute');
                            //处理套装属性
                            this.getArray(this.detailObj, 'suit_attribute');
                            //处理生肖属性
                            this.getArray(this.detailObj, 'animals_attribute');
                        }
                    }
                    console.log(this.detailObj)
                });
            }
        },
        //将对象转化成数组
        getArray(obj, key) {
            // console.log(obj[key])
            if (obj[key]) {
                obj[key] = Object.values(obj[key]);
            }else{
                obj[key] = [];
            }
        },
        //接收子组件弹窗关闭事件
        popShowClick(val) {
            this.wrapperShow = val;
        }
    },
    
}
</script>
<style scoped>
@import "../../assets/css/common.css"
</style>